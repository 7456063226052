import * as React from 'react';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlhref,
} from '../../../utils';
import * as Styled from './accountTemplateStyles';

const AccountTemplate = (props) => {
  const linkText = props?.textWithLink1?.text?.split(' ');
  return (
    <Styled.AccountContainer
      className="g-0"
      $backgroundImage={props?.backgroundImages[0]?.file?.url}
      $backgroundImageSmallDevice={props?.backgroundImages[1]?.file?.url}
      $backgroundImageMediumDevice={props?.backgroundImages[2]?.file?.url}
      $isLeft={props?.isLeft}
    >
      <Styled.WrapperInnerDiv $isLeft={props?.isLeft}>
        <Styled.AnchorAccountType
          target={
            isExternalUrl(props?.textWithLink1?.path) ? '_blank' : '_self'
          }
          href={
            isExternalUrlhref(props?.textWithLink1?.path)
              ? props?.textWithLink1?.path
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + props?.textWithLink1?.path
                )
          }
          $background={props?.anchorBGColor}
          $hoverColor={props?.anchorHoverColor}
        >
          {linkText[0]}
          <Styled.InnerSpan>{linkText[1]}</Styled.InnerSpan>
        </Styled.AnchorAccountType>
      </Styled.WrapperInnerDiv>
    </Styled.AccountContainer>
  );
};

export default AccountTemplate;
